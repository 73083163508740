<template>
    <div class="permissionsBox item-b"  v-loading="showLoad" element-loading-text="拼命加载中">
        <FormItem :formList="formList" :btn="btn" @search="onSearch" @add="onAdd" @reset="onReset" :btnObj="btnObj" :search="search"/>
        <Table ref="table" :tableList="tableList" :tableData="tableData" @operation="onHandle" @currentNum="current" :total="total"/>
        <el-drawer custom-class="drawer-class" :visible.sync="show"  :show-close="false" :withHeader="false" :wrapperClosable="false">
            <div class="drawer-box flex-col">
                <div class="title" v-if="!isEdit">新增用户</div>
                <div class="title" v-else>编辑用户</div>
                <div class="table auto">
                    <el-form
                            :model="postFormData"
                            size="small"
                            :rules="postFormDataRules"
                            label-position="left"
                            label-width="auto"
                            ref="addForm"
                            :hide-required-asterisk="true"
                    >
                        <el-form-item label="用户名称" prop="userName">
                            <el-input placeholder="请输入用户名" v-model="postFormData.userName" class="custom-submit-input"></el-input>
                        </el-form-item>
                        <el-form-item label="角色名称" prop="roleName">
                            <el-select v-model="postFormData.roleName" placeholder="请选择角色" class="custom-submit-select" @change="handleRole">
                                <el-option
                                        v-for="item in roleOptions"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="所属项目部：" prop="departmentName">
                            <el-select v-model="postFormData.departmentName" placeholder="请选择部门" class="custom-submit-select" @change="handleDepartment">
                                <el-option
                                        v-for="item in departmentOptions"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="联系电话" prop="phone">
                            <el-input placeholder="请输入联系电话" v-model="postFormData.phone" class="custom-submit-input"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="c-center btn">
                    <el-button type="primary submit-primary" v-if="!isEdit" @click="saveAdd">保存</el-button>
                    <el-button type="primary submit-primary" v-else @click="update">保存</el-button>
                    <el-button type="info submit-info" @click="cancelAdd">取消</el-button>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import axios from '@/request'
export default {
    name: "Permissions",
    data(){
      return{
        showLoad:false,
        formList:[
          {
            props:'userName',
            el:'el-input',
            label:'用户名',
            placeholder:'请输入用户名称'
          },
        ],
        btnObj:{
          search:'查询',
          add:'新增',
          reset:'重置'
        },
        btn:'search,add,reset',
        tableList:[
          {
            prop:'index',
            label:'序号',
            width:''
          },
          {
            prop:'userName',
            label:'用户名称',
            width:''
          },
          {
            prop:'roleName',
            label:'角色名称',
            width:''
          },
          {
            prop:'departmentName',
            label:'所属项目部',
            width:''
          },
          {
            prop:'phone',
            label:'联系电话',
            width:''
          },
          {
            prop:'op',
            label:'操作',
            btn:[
              {
                type:'primary',
                name:'编辑',
                prop:'edit',
              },
              {
                type:'danger',
                name:'删除',
                prop:'del',
              }
            ]
          }
        ],
        tableData:[],
        page:1,
        size:10,
        total:0,
        search: {
          userName:''
        },
        show: false,
        departmentOptions:[],
        roleOptions:[],
        postFormData:{},
        postFormDataRules:{
          userName:[
            { required: true, message: "请输入用户名称" },
            { max: 20, message: "最多20个字符" },
          ],
          roleName:[
            { required: true, message: "请选择角色名称" },
          ],
          departmentName:[
            { required: true, message: "请选择所属项目部" },
          ],
          phone: [
            { required: true, message: "请输入联系电话" },
            {  tyep: "regexp", pattern: /^1[0-9]{10}$/, message: "请输入正确的联系电话" }
          ],
        },
        isEdit:false
      }
    },
    mounted() {
      this.getData(this.page,this.size,this.search)
    },
    methods:{
      // 角色
      role(){
        axios.get('/role/findAll').then(res=>{
          this.roleOptions = res.map(item => {
            return {
              value:item.id,label:item.roleName
            }
          })
        })
      },
      // 项目部
      department(){
        axios.get('/projectDept/findAll').then(res=>{
          this.departmentOptions = res.map(item => {
            return {
              value:item.id,label:item.deptName
            }
          })
        })
      },
      getData(page,size,val){
        this.showLoad=true
        axios.get(`/user/page/${page}/${size}`,{
          params:{
            ...val
          }
        }).then(res => {
          this.tableData = res.list
          this.total = res.total
          this.showLoad=false
        })
      },
      onSearch(val){
        this.search = val
        this.getData(this.page,this.size,val)
      },
      onAdd(val){
        this.department()
        this.role()
        this.show = true
        this.isEdit = false
        this.postFormData = Object.assign({},{})
      },
      onReset(val){
        this.$refs.table.lastEmittedPage()
        this.getData(1,10,val)
      },
      // 表格操作按钮
      onHandle(data){
        // opVal ：执行表格编辑删除等操作
        if (data.opVal == 'del'){
          this.del(data.data)
        }else if (data.opVal == 'edit'){
          this.edit(data.data)
        }
      },
      // 分页事件
      current(val){
        this.page = val
        this.getData(this.page,this.size,{})
      },
      cancelAdd() {
        this.$refs.addForm.resetFields();
        this.show = false;
      },
      edit(data){
        this.department()
        this.role()
        this.isEdit = true
        this.show = true
        this.postFormData = Object.assign({},data)
      },
      handleRole(val){
        let roleObj = this.roleOptions.find(item => {
          return item.value === val
        })
        this.$set(this.postFormData,'roleId',roleObj.value)
        this.$set(this.postFormData,'roleName',roleObj.label)
      },
      handleDepartment(val){
        let departmenObj = this.departmentOptions.find(item => {
          return item.value === val
        })
        this.$set(this.postFormData,'departmentId',departmenObj.value)
        this.$set(this.postFormData,'departmentName',departmenObj.label)
      },
      saveAdd() {
        this.$refs.addForm.validate(valid => {
          if(valid){
            let {departmentId,departmentName,roleId,roleName,phone,userName} = this.postFormData
            let dataObj = {
              departmentId,departmentName,roleId,roleName,phone,userName
            }
            axios.post('/user/add',{
              ...dataObj
            }).then(res=>{
              if (res == null){
                this.$message({
                  message: '新增成功',
                  type: 'success'
                });
                this.cancelAdd()
                this.getData(this.page,this.size,{})
              }else{
                this.$message({
                  message: '新增失败',
                  type: 'error'
                });
              }
            })
          }
        });
      },
      update(){
        this.$refs.addForm.validate(valid => {
          if(valid){
            let {id,departmentId,departmentName,roleId,roleName,phone,userName} = this.postFormData
            let dataObj = {
              id,departmentId,departmentName,roleId,roleName,phone,userName
            }
            axios.post('/user/update',{
              ...dataObj
            }).then(res=>{
              if (res == null){
                this.$message({
                  message: '修改成功',
                  type: 'success'
                });
                this.cancelAdd()
                this.getData(this.page,this.size,this.search)
              }else{
                this.$message({
                  message: '修改失败',
                  type: 'error'
                });
              }
            })
          }
        });
      },
      del(data){
        this.$confirm('此操作将永久删除用户信息, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          axios.post(`/user/deleteById/${data.id}`).then(res=>{
            if (res == null){
              this.$message({
                message: '删除成功',
                type: 'success'
              });
              this.getData(this.page,this.size,this.search)
            }else{
              this.$message({
                message: '删除失败',
                type: 'error'
              });
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
    }
}
</script>

<style lang="scss" scoped>
.permissionsBox{
    width: 100%;
    .input{
        width: 100%;
    }
    .drawer-box {
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        overflow: auto;

        .title {
            padding: 20px;
            font-size: 1.2rem;
            font-weight: bold;
            text-align: center;
            border-bottom: solid 1px #dadada;
        }
        .table {
            padding: 20px;
            overflow: auto;
        }
        .btn {
            padding: 30px 0;
        }
    }
}

</style>
